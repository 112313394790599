import {
  addDays,
  format,
  startOfWeek,
  getMonth,
  eachDayOfInterval,
  lastDayOfMonth,
  isSameDay
} from 'date-fns'
import React from 'react'
import { Box, Flex, Text } from 'rebass'

// We want a calendar that covers 30 days,
// displays full weeks starting on sunday
// So this will always be 5x weeks total
const totalAmountOfActiveCalendarDays = 30

const getCalendarDates = (dischargeDate: Date) => {
  // First active calendar day is 'dischargeDate'

  // But we need the actual start of the calendar to be the start of week
  const startDate = startOfWeek(dischargeDate, { weekStartsOn: 0 })

  const endDate = addDays(
    startDate,
    totalAmountOfActiveCalendarDays + dischargeDate.getDay()
  )

  // The first month to be taken in consideration is the one related to 'today'
  // not to the 'start of the week'
  // i.e. Today is 1st and the start of the week takes place in the previous month
  const startDateMonth = getMonth(dischargeDate)

  const endDateMonth = getMonth(endDate)

  const date = new Date(startDate.getTime())

  const lastDayEndOfMonth = lastDayOfMonth(dischargeDate)

  const firstDayOfLastWeekStartMonth = startOfWeek(lastDayEndOfMonth, {
    weekStartsOn: 0
  })

  // If the active calendar days are splitted in two months
  // we need the first days of the first week of the second month to display them as 'not active' (prev days)
  // if not the calendar will start displayin always the first day as 'Sunday' which is not valid
  const firstDaysLastMonth = eachDayOfInterval({
    start: firstDayOfLastWeekStartMonth,
    end: lastDayEndOfMonth
  })

  // We build the object with the 'months' to be displayed
  const dates = {
    [startDateMonth]: [],
    ...(startDateMonth !== endDateMonth && {
      [endDateMonth]: [...firstDaysLastMonth]
    })
  }

  // Here we loop through the days between the first start active days and the end date
  // and we include each one to the corresponding month
  while (date <= endDate) {
    const newDate = new Date(date)
    const newDateMonth = getMonth(newDate)

    //This is to handle the scenario where first days of first month belong to previous month
    // We don't want to create a visual month for those days
    const belongsToMonth =
      newDateMonth >= startDateMonth ? newDateMonth : startDateMonth

    dates[belongsToMonth].push(newDate)
    date.setDate(date.getDate() + 1)
  }

  return dates
}

type LabelProps = {
  label: string
}

const WeekLabel = ({ label }: LabelProps) => (
  <Box sx={{ flex: 1, alignContent: 'center', px: 1, py: 2 }}>
    <Text variant="label" sx={{ textAlign: 'center', m: 0 }}>
      {label}
    </Text>
  </Box>
)

type CalendarDayProps = {
  date: Date
  children: React.ReactNode
}

const CalendarDay = ({
  date,
  monthIndex,
  dischargeDate,
  children
}: CalendarDayProps) => {
  const d = format(date, 'd')
  const m = format(date, 'MMMM')

  const maxDate = addDays(
    dischargeDate,
    totalAmountOfActiveCalendarDays + dischargeDate.getDay()
  )
  const activeDay = isSameDay(date, dischargeDate)
  const dateMonth = getMonth(date)
  const activesDayMonth = getMonth(dischargeDate)

  const dateActive =
    monthIndex > 0
      ? activesDayMonth !== dateMonth && date.getTime() < maxDate.getTime()
      : activeDay ||
        (date.getTime() >= dischargeDate.getTime() &&
          date.getTime() < maxDate.getTime())

  const isDayWithLabel = d === '1' || activeDay
  const dayString = isDayWithLabel ? d + ' ' + m : d

  return (
    <Flex
      sx={{
        label: 'DScalendarDay',
        position: 'relative',
        justifyContent: 'space-between',
        minHeight: [
          '56px',
          '56px',
          '56px',
          '56px',
          '56px',
          '56px',
          '56px',
          '85px'
        ],
        backgroundColor: dateActive ? 'white' : 'greyLight',
        flexDirection: 'column',
        pb: 1
      }}
    >
      {dateActive && (
        <Text
          variant="contentMedium"
          sx={{
            fontSize: isDayWithLabel ? '18px !important' : 'inherit',
            fontWeight: isDayWithLabel ? 'bold' : 'inherit',
            m: 1
          }}
        >
          {dayString}
        </Text>
      )}
      {children}
    </Flex>
  )
}

type CalendarAppointmentProps = {
  highlight: string
  information: string
}

const CalendarAppointment = ({
  highlight,
  information
}: CalendarAppointmentProps) => {
  return (
    <Box
      sx={{
        background: highlight,
        mx: 1,
        my: '2px'
      }}
    >
      <Text
        variant="labelSmall"
        sx={{
          color: 'text',
          lineHeight: 1
        }}
      >
        {information}
      </Text>
    </Box>
  )
}

type CalendarProps = {
  children: React.ReactNode
}

const Calendar = ({ children }: CalendarProps) => {
  return (
    <>
      <Flex>
        <WeekLabel label="Sun" />
        <WeekLabel label="Mon" />
        <WeekLabel label="Tues" />
        <WeekLabel label="Wed" />
        <WeekLabel label="Thu" />
        <WeekLabel label="Fri" />
        <WeekLabel label="Sat" />
      </Flex>
      <Box
        sx={{
          display: 'grid',
          gridGap: '1px',
          gridTemplateColumns: 'repeat(7,minmax(0,1fr))'
        }}
      >
        {children}
      </Box>
    </>
  )
}

export { CalendarAppointment, CalendarDay, getCalendarDates }
export default Calendar
