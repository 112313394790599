import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import ReactTooltip from 'react-tooltip'
import { Button } from 'rebass'
import { trackHandler, useTranscript, useUneeqState } from 'uneeq-react-core'
import { ReactComponent as BackIcon } from '../../app/assets/icons/back-alt.svg'
import { useSendSocketMessage } from '../../app/hooks/useSendSocketMessage'
import { useUneeqContext } from '../../app/hooks/useUneeqContext'

const sendBackMessage = (sendMessage, transcript) => {
  // determine how back we need to go (to account for skipped questions)
  const questions = [...transcript.filter(item => item.type === 'question')]
  const previousQuestions = questions.slice(0, questions.length - 1) // take out the last question which is the one we're currently responding to

  const lastQuestion = previousQuestions[previousQuestions.length - 1]
  // if previous question wasn't skipped, send a regular back message
  if (!lastQuestion?.skipped) {
    sendMessage({ type: 'back' })
  } else {
    // if previous question was skipped, check if previous were skipped too,
    // in order to determine how far back we need to go
    const notSkippedIndex = previousQuestions.findLastIndex(
      item => !item.skipped
    )
    const skippedQuestions = previousQuestions.slice(notSkippedIndex)

    sendMessage({ type: 'back', step: skippedQuestions.length })
  }
}

interface MayaBackButtonProps {
  onClick?: () => any
  iterateBack?: boolean
  sx?: any
}

const MayaBackButton = ({
  onClick,
  sx = {},
  iterateBack = false
}: MayaBackButtonProps) => {
  const { t } = useTranslation()
  const { transcript } = useTranscript()
  const { mayaQuestion, linesToSpeak } = useUneeqState()

  const currentLine = linesToSpeak?.find(
    (line: any, index) => line?.currentlySpeaking
  )

  const sendMessage = useSendSocketMessage()
  const { dispatch } = useUneeqContext()

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [])

  const back = () => {
    if (onClick) {
      onClick()
    }

    if (currentLine?.eachIndex && iterateBack) {
      dispatch &&
        setTimeout(
          () =>
            dispatch({
              type: 'mayaMessage',
              payload: { ...mayaQuestion, type: 'talkPreviousItem' }
            }),
          100
        )
    } else {
      sendBackMessage(sendMessage, transcript)
    }
  }

  if (transcript.length < 2) return <div>&nbsp;</div>

  return (
    <Button
      id="back"
      variant="tertiary"
      data-tip={t('Tooltip.backButton')}
      sx={{
        flexGrow: 0,
        flexShrink: 0,
        marginBottom: 5,
        marginRight: 5,
        ...sx
      }}
      onClick={trackHandler(back, 'back-btn')}
    >
      <BackIcon />{' '}
      {currentLine?.eachIndex && iterateBack ? 'Previous Med' : 'Previous Info'}
    </Button>
  )
}

export default MayaBackButton
