import tokenizer from 'sbd'
import { Uneeq } from 'uneeq-js'
import { dischargeCarePlanType } from '../app/types/dischargeCarePlan'
import { sendMessage } from '../socket'
import { decodeHTMLEntities, removeHTMLTags } from '../utils'
import {
  apptFormatItem,
  coMorbiditiesFormatList,
  medicalEquipmentFormatItem,
  medicationFormatItem,
  parseIfandEachMatches,
  testsFormatItem
} from './mayaEchoMiddlewareHelper'

const replacements = (dischargeCarePlan: dischargeCarePlanType) => {
  const {
    discharge_cc,
    medical_conditions,
    diet_education,
    exercise_education,
    emergency_contact_numbers,
    other_emergency_contact_numbers,
    medical_conditions_other,
    home_care_agency,
    quiz_data,
    provider
  } = dischargeCarePlan

  const correctDate = quiz_data.nextAppointmentDateString

  const quizMedication = quiz_data.randomCorrectMedication

  const nurseName = `${discharge_cc?.first_name ||
    ''} ${discharge_cc?.last_name || ''}`

  // For recreating the Symptom List, if there's a Symptom Description to be read use that one
  // Otherwise read the complete Symptom List
  const symptomList = medical_conditions
    ? medical_conditions[0]?.medical_condition?.education_content
      ? decodeHTMLEntities(
          removeHTMLTags(
            // We check first for the Symptom Description as we can have both education_content
            medical_conditions[0]?.medical_condition?.education_content.find(
              content => content.title === 'Symptom Description'
            )?.description ??
              medical_conditions[0]?.medical_condition?.education_content.find(
                content => content.title === 'Symptom List'
              )?.description
          )
        )
      : ''
    : ''

  return {
    '%nickname%': dischargeCarePlan?.patient_preferred_name,
    '%delivery%': 'pill',
    '%date%': correctDate,
    '%contact_no%': emergency_contact_numbers[0]?.contact_number || '',
    '%_id: 2 medicineName%': quizMedication.medicineName,
    '%dosage%': quizMedication.dosage.value,
    '%CAREGIVER%': dischargeCarePlan?.personal_caregiver_name,
    '%caregiver_name%': dischargeCarePlan?.personal_caregiver_name,
    '%DISCHARGE_NURSE': nurseName,
    '%discharged_by%': nurseName,
    '%primary_diagnosis%': `${medical_conditions[0]?.medical_condition?.name ||
      ''}`,
    '%physician_name%': provider,
    '%cardiologist_name%': provider,
    '%primary_care%': other_emergency_contact_numbers[0]?.provider,
    '%home_health_care%': home_care_agency?.name ?? '',
    '%organization_name%': discharge_cc?.organization?.organization_name,
    '%office_phone%': emergency_contact_numbers[0]?.contact_number || '',
    '%PT_PREFERRED_NAME%': dischargeCarePlan?.patient_preferred_name,
    'LIST #co-morbidities#': coMorbiditiesFormatList(medical_conditions_other),
    '#APPOINTMENTS#': 'Appointments',
    '#NOT _SCHEDULED#': 'Not scheduled',
    '#SCHEDULED#': 'Scheduled',
    '//start_highlight_diagnosis//': '',
    '//end_highlight_diagnosis//': '',
    '#SHOW: changed_medications#': '',
    '#SHOW: unchanged_medications#': '',
    '#SHOW: scheduled_appointments#': '',
    '#SHOW: unscheduled_appointments#': '',
    '#SHOW: Symptoms List 1#': '',
    '#SHOW: education: symptom_list#': '',
    '%education: symptom_description%': symptomList,
    '#SHOW: final_bullets#': '',
    [`#SHOW: 'What To Do If You Have a Problem' section of AHCP#`]: '',
    '#SHOW: office_phone#': '',
    '#SHOW: Symptoms List 2#': '',
    '--SKIP_IF_NONE--': '',
    '#SKIP if none#': '',
    '--SKIP if none--': '',
    '#Upcoming_Tests#': '',
    '#if 1#': '',
    '#end-if 1#': '',
    '#end-if more than 1#': '',
    '#medical_equipment#': '',
    '#first#': '',
    '#end- first#': '',
    '#education: diet#': diet_education[0]?.description || '',
    '#education: exercise#': exercise_education[0]?.description || '',
    '#second#': '',
    '#end- second#': '',
    '#if more than 1#': '',
    '#pending_test#': '',
    '#if 1 test#': '',
    '#if more than 1 test#': '',
    '%diet%': diet_education[0]?.title || '',
    '%exercise_plan%': exercise_education[0]?.title || '',
    '#upcoming_tests#': '',
    '--Start-Each--': '',
    '--End-Each--': '',
    '--start-each--': '',
    '--end-each--': '',
    '//reason//': '',
    '//genericName//': '',
    '//medicineName//': '',
    '//medicationName//': '',
    '#Hide#': '',
    '#SHOW: diagnosis#': '',
    '#SKIP if no caregiver#': '',
    '%_id: 1, medical_condition%': `${medical_conditions[0]?.medical_condition
      ?.name || ''}`,
    '#education: primary_diagnosis#': `${medical_conditions[0]
      ?.medical_condition?.description || ''}`,
    '#education: specific_diagnosis#':
      'MISSING VARIABLE education: specific_diagnosis'
  }
}

const replaceArray = (
  stringToParse: string,
  find: string[],
  replace?: string[],
  item?: any,
  replaceFn?: any,
  containsNewLines?: boolean = true
) => {
  if (!stringToParse) {
    return stringToParse
  }
  // first we remove all \n
  // it needs to be a space - if not sometimes it doesn't take it as a new line
  if (containsNewLines) {
    stringToParse = stringToParse.replace(/\r?\n|\r/g, ' ')
  }

  // then replace all characters from find array and
  // replace with character in the same position in
  // the replace array
  var regex
  for (var i = 0; i < find.length; i++) {
    regex = new RegExp(find[i], 'g')
    if (!replaceFn) {
      stringToParse = stringToParse.replace(regex, replace[i])
    } else {
      stringToParse = stringToParse.replace(regex, replaceFn(item, find[i]))
    }
  }

  return stringToParse
}

const getHighlightFromSentence = (sentence: string, index, prevHighlight) => {
  const capturingRegex = /\/\/row_x_column_(?<column>.*)\/\//
  const found = sentence.match(capturingRegex)

  // Move row when:
  // the previous highlight has a column greater or equal to the one we have retrieved

  const row =
    prevHighlight && found && prevHighlight.column >= found.groups.column
      ? index + 1
      : index

  return found
    ? {
        highlight: `row_${row}_column_${found.groups.column}`,
        row,
        column: found.groups.column
      }
    : null
}

const removeHighlightsFromSentence = (sentence: string) => {
  const reg = /(\/\/(.*)\/\/)/g
  return sentence.replace(reg, '')
}

const genericReplaceFn = (item: any, varName: string) => {
  const variableMap = {
    medicinename: 'medicineName',
    genericname: 'genericName'
  }

  if (varName.includes('%')) {
    const fixedVarName = varName.replace(/%/g, '')
    const finalVar = variableMap[fixedVarName] || fixedVarName
    return item[finalVar]
  } else {
    const finalVar = variableMap[varName] || varName

    return item[finalVar] ?? varName
  }
}

const iterationData = dischargeCarePlan => {
  const {
    firstInstanceMedications,
    secondInstanceMedications,
    follow_ups,
    medical_conditions,
    medical_conditions_other,
    upcoming_lab_tests,
    completed_lab_tests,
    medical_equipments
  } = dischargeCarePlan

  return {
    changed_medications: {
      iterationArray: firstInstanceMedications,
      findHighlightsInText: true,
      formatItem: medicationFormatItem,
      replaceFn: genericReplaceFn
    },
    unchanged_medications: {
      iterationArray: secondInstanceMedications,
      findHighlightsInText: true,
      formatItem: medicationFormatItem,
      replaceFn: genericReplaceFn
    },
    scheduled_appointments: {
      iterationArray: follow_ups.filter(
        followUp => followUp.date_of_appointment
      ),
      findHighlightsInText: true,
      formatItem: apptFormatItem,
      replaceFn: genericReplaceFn
    },
    unscheduled_appointments: {
      iterationArray: follow_ups.filter(
        followUp => !followUp.date_of_appointment
      ),
      findHighlightsInText: true,
      formatItem: apptFormatItem,
      replaceFn: genericReplaceFn
    },
    diagnosis: {
      formatItem: item => item, //TODO
      findHighlightsInText: false,
      iterationArray: [medical_conditions, medical_conditions_other]
    },
    upcoming_tests: {
      iterationArray: upcoming_lab_tests,
      findHighlightsInText: false,
      formatItem: testsFormatItem,
      replaceFn: genericReplaceFn
    },
    pending_test: {
      iterationArray: completed_lab_tests,
      findHighlightsInText: false,
      formatItem: testsFormatItem,
      replaceFn: genericReplaceFn
    },
    medical_equipment: {
      iterationArray: medical_equipments,
      findHighlightsInText: false,
      formatItem: medicalEquipmentFormatItem,
      replaceFn: genericReplaceFn
    },
    co_morbidities: {
      iterationArray: medical_conditions_other,
      formatItem: item => item //TODO
    }
  }
}

const getSentences = (sentences, findHighlight, itemsAmountToIterate) => {
  let lastHighlight = null

  // We have 2 different type of sentences
  // The ones with cells and rows to highlight
  // And the ones with no highlights

  // If we don't need the amount of items, we just iterate over the sentences to find the highlight inside the text
  return sentences.map((sentence, index) => {
    const sentenceMetadata = {
      text: sentence,
      speak: removeHighlightsFromSentence(sentence),
      currentlySpeaking: index === 0
    }

    // If there's no need to find a highlight, return the sentenceMetadata without highlight and eachIndex
    if (!findHighlight)
      return {
        ...sentenceMetadata,
        highlight: null,
        eachIndex: itemsAmountToIterate >= 1 ? index : null
      }

    // Otherwise, go to the process to find the next highlight and add it to the sentence metadata
    const indexToFind = lastHighlight !== null ? lastHighlight.row : 0

    const highlight = getHighlightFromSentence(
      sentence,
      indexToFind,
      lastHighlight
    )

    if (highlight) lastHighlight = highlight

    return {
      ...sentenceMetadata,
      highlight: highlight?.highlight || null,
      eachIndex: highlight?.row || indexToFind
    }
  })
}

const getTextToReplace = (
  innerSection: string | undefined,
  textWithoutTitle: string,
  textBeforeEach: string | undefined,
  textInsideEach: string | undefined,
  textAfterEach: string | undefined,
  iterationData: any
) => {
  // if no EACH found, we just return the text (later, on
  // another fn, we'll remove the innerSection string)
  if (!textInsideEach) return textWithoutTitle

  // if we have an EACH, get the iteration array
  if (innerSection && innerSection in iterationData) {
    const iterationArray = iterationData[innerSection].iterationArray

    // we start with any text that was before the EACH
    let returnString = textBeforeEach

    // iterate over each item, and add text inside each,
    // with replaced tags
    iterationArray.forEach((item: any, index) => {
      // get list of tags to replace

      const variablesList = textInsideEach.match(/(%)(.*?)(%)/gs)

      // For between ## text. Eg. #education: medical_equipment#
      const textToBeReplaced = textInsideEach.match(/(#)(.*?)(#)/gs)

      // For optional ## text. Eg. #new_medications#
      const textToBeCheckedWithItemInformation = textInsideEach.match(
        /(#)(.*?)(#)[\r\n]+([^\r\n]+)/gs
      )

      const formattedItem = iterationData[innerSection].formatItem(
        {
          ...item,
          index: index + 1
        },
        textToBeCheckedWithItemInformation
      )

      const { extraReplacements } = formattedItem

      const extraReplacementsKeys = Object.keys(extraReplacements || {})
      const extraReplacementsValues = Object.values(extraReplacements || {})

      // parse extra replacements for this specific item
      // FIRST we need to replace the extra replacements in case they contain variables
      let eachInstanceText = replaceArray(
        textInsideEach,
        extraReplacementsKeys,
        extraReplacementsValues
      )

      eachInstanceText = replaceArray(
        eachInstanceText,
        [
          ...variablesList,
          // TODO: Maki: can we tweak the Regex above so as to not add it for new_medications and changed_medications?
          // ...(optionalTextToBeReplaced ? optionalTextToBeReplaced : []),
          ...(textToBeReplaced ? textToBeReplaced : [])
        ],
        undefined,
        formattedItem,
        iterationData[innerSection].replaceFn,
        false
      )

      // If the iteration has a long variablesList or has textToBeReplaced it means that is a longer iteration list which doesn't needs to be concatenated
      // Otherwise is a shorter list which needs concatenation

      const concatenateString =
        variablesList?.length > 1 || textToBeReplaced
          ? ''
          : iterationArray.length === 1 || iterationArray.length === index + 1
          ? ''
          : iterationArray.length - 1 === index + 1
          ? ` and`
          : ','

      returnString = `${returnString}
      ${eachInstanceText}
      ${concatenateString}`
    })

    // add text after EACH
    returnString = `${returnString}
    ${textAfterEach}
    `

    return returnString
  }
  return textWithoutTitle
}

const processSkip = (
  skipMatches,
  skippedData,
  action,
  dispatch,
  sendMessageWithSession
) => {
  if (skipMatches?.length > 0) {
    setTimeout(() => {
      const questionId = action?.payload?.id
      const responseInfo = skippedData[questionId]

      // check if we have to skip or not
      if (!responseInfo || !responseInfo.shouldSkip) {
        return
      }

      const info = {
        type: 'response',
        questionId: questionId,
        response: responseInfo.response,
        label: responseInfo.label,
        skipped: true
      }
      console.log('SKIPPING', questionId)
      dispatch({ type: 'mayaMessage', payload: info })
      sendMessageWithSession(info)
    }, 100)
    return
  }
}

// Send any questions to UneeQ echo backend to be spoken
const mayaEchoMiddleware = (dischargeCarePlan: dischargeCarePlanType) => (
  state: any,
  action: any,
  uneeq: Uneeq,
  uneeqContext: any
) => {
  const dispatch = uneeqContext?.dispatch
  const currentSession = state?.session
  const sendMessageWithSession = sendMessage(currentSession)

  console.log('uneeqContext', uneeqContext)

  if (
    action.type === 'mayaMessage' &&
    action.payload.type === 'question' &&
    !action.payload.mute &&
    !state.loadingVideoActive
  ) {
    const sessionData = iterationData(dischargeCarePlan)

    const speak = action.payload.speak || action.payload.question

    // Leave this for Mica to test what we're receiving from the script
    console.log('RECEIVED SCRIPT', speak)

    // ---- v1: Speak everything at once ---
    // 1. Replace variables with API data
    // 2. Find Section Title in text, save in state, remove from text
    // 3. Find EACH marker. If so, replace text between EACH markers
    // with text replace with item data from API, one for each item
    //    3.1. Find which section we're on (##SHOW:)
    //    3.2. Get replacement object for section (to correctly map
    //         each variable to the right data)
    //    3.3. Get array from data which we'll be iterating on
    //    3.4. Iterate over array replacing data from replacement object
    //         and generate string for each item, concatenate them all
    // 4. Separate text by new lines
    // 5. Parse highlight markers
    // 6. Make Maya speak each line

    // ---v2: make each item in loop to be spoken separately, on UI
    // prompt from the user ---
    // Once an item finishes, the user has to click a button
    // to speak the next one (or repeat the same)

    /*

--TITLE: Medicines--
All the medicines you will take are listed here.

#SHOW: changed_medications#
--start-each--
//highlight_row_x_column_1// 
This medicine is called %medicineName%. 
//highlight_generic_name// 
It is also called %genericName%.
//highlight_reason//
You are taking it for your %reason%.

#new_medications#
It is a medicine that you were not taking before you came to the hospital.
#changed_medications#
It is a medicine that you were taking before, but you are now on a new dose.
--end-each--

Would you like to hear about your existing medicines that have not been changed?
    
    */

    // parse Section Title from question text
    const titleMatches = speak?.match(/(--TITLE: )(.*?)(--)(.*)/s)

    // There're cases where the --TITLE-- is not there, so complete the array with just the speak variable
    const [, , , , textWithoutTitle] = titleMatches || ['', '', '', '', speak]

    // SKIP if no caregiver is found
    const skipMatches = textWithoutTitle?.match(/(SKIP if)/)

    processSkip(
      skipMatches,
      dischargeCarePlan.skippedData,
      action,
      dispatch,
      sendMessageWithSession
    )

    // parse section to show (for EACH iteration)
    //Make the #SHOW: to be not capturing and optional to be just #
    const innerSectionMatches = speak?.match(
      /(?:#SKIP if none#\n#?)?(?:#SHOW: |#)(.*?)(#)/s
    )

    const [, innerSection] = innerSectionMatches || []

    const itemsAmountToIterate =
      sessionData[innerSection]?.iterationArray.length || 0

    // parse EACH tags (for repeated items like medications, appointments, etc.)
    // considering the IF cases for 1 or more than 1 elements to be iterated

    const {
      textBeforeEach,
      textInsideEach,
      textAfterEach
    } = parseIfandEachMatches(textWithoutTitle, itemsAmountToIterate)

    // get text to use in replacements (with iteration text added in case
    // of EACH, or just the regular text)
    const textToReplace = getTextToReplace(
      innerSection,
      textWithoutTitle,
      textBeforeEach,
      textInsideEach,
      textAfterEach,
      sessionData
    )

    // replace variables
    let stringToSpeak = replaceArray(
      textToReplace, // textWithoutTitle, // speak,
      Object.keys(replacements(dischargeCarePlan)),
      Object.values(replacements(dischargeCarePlan))
    )

    // first we remove all \n
    // TODO: parse sentences by new lines, remove empty lines
    const sentences = tokenizer.sentences(stringToSpeak, {
      newline_boundaries: true
    })

    const findHighlight = sessionData[innerSection]?.findHighlightsInText

    const linesToSpeak = getSentences(
      sentences,
      findHighlight,
      itemsAmountToIterate
    )

    // make Maya speak the first line
    const linesToSpeakArray = linesToSpeak
    !state.sessionPaused &&
      linesToSpeakArray &&
      linesToSpeakArray[0] &&
      uneeq?.sendTranscript(linesToSpeakArray[0].speak)

    // set lines to speak in state
    dispatch &&
      setTimeout(
        () =>
          dispatch({
            type: 'linesToSpeakSet',
            payload: { linesToSpeak, stringToSpeak }
          }),
        100
      )
  }
  if (
    state.linesToSpeak?.length > 0 &&
    action.type === 'uneeqMessageFinishedSpeaking'
  ) {
    console.log('MIDDLEWARE FINISHED SPEAKING')
    const currentLineIndex = state.linesToSpeak.findIndex(
      (line: any) => line.currentlySpeaking === true
    )

    // update linesToSpeak
    const newLinesToSpeak = state.linesToSpeak.map(
      (line: any, index: number) => {
        if (index === currentLineIndex) {
          return { ...line, currentlySpeaking: false }
        }
        if (index === currentLineIndex + 1) {
          return { ...line, currentlySpeaking: true }
        }
        return { ...line }
      }
    )

    dispatch &&
      setTimeout(
        () =>
          dispatch({
            type: 'linesToSpeakSet',
            payload: {
              linesToSpeak: newLinesToSpeak,
              stringToSpeak: state.stringToSpeak
            }
          }),
        100
      )
    // speak next line
    const nextLine = newLinesToSpeak[currentLineIndex + 1]

    !state.sessionPaused && nextLine && uneeqContext?.speakLine(nextLine?.speak)
  }
  // Modify linesToSpeak array in case next button has been pressed
  // TODO can be unified into the same if - but maybe is better to have them separate for better undestanding
  if (action.type === 'mayaMessage' && action.payload.type === 'talkNextItem') {
    const currentLineIndex = state.linesToSpeak.findIndex(
      (line: any) => line.currentlySpeaking === true
    )
    const currentLine = state.linesToSpeak.find(
      (line: any) => line.currentlySpeaking === true
    )

    const followingLine = state.linesToSpeak.findIndex(
      (line: any) => line.eachIndex === currentLine.eachIndex + 1
    )

    //If the next line is negative, prevent looping through the table
    if (followingLine < 0) return

    // update linesToSpeak
    const newLinesToSpeak = state.linesToSpeak.map(
      (line: any, index: number) => {
        if (index === currentLineIndex) {
          return { ...line, currentlySpeaking: false }
        }
        if (followingLine > 0 && index === followingLine) {
          return { ...line, currentlySpeaking: true }
        }
        return { ...line }
      }
    )

    dispatch &&
      setTimeout(
        () =>
          dispatch({
            type: 'linesToSpeakSet',
            payload: {
              linesToSpeak: newLinesToSpeak,
              stringToSpeak: state.stringToSpeak
            }
          }),
        100
      )
    // speak next line
    const nextLine = newLinesToSpeak[followingLine]

    !state.sessionPaused && nextLine && uneeqContext?.speakLine(nextLine?.speak)
  }
  // Modify linesToSpeak array in case back button has been pressed
  if (
    action.type === 'mayaMessage' &&
    action.payload.type === 'talkPreviousItem'
  ) {
    const currentLineIndex = state.linesToSpeak.findIndex(
      (line: any) => line.currentlySpeaking === true
    )
    const currentLine = state.linesToSpeak.find(
      (line: any) => line.currentlySpeaking === true
    )

    const previousLine = state.linesToSpeak.findIndex(
      (line: any) => line.eachIndex === currentLine.eachIndex - 1
    )

    // update linesToSpeak
    const newLinesToSpeak = state.linesToSpeak.map(
      (line: any, index: number) => {
        if (index === currentLineIndex) {
          return { ...line, currentlySpeaking: false }
        }
        if (index === previousLine) {
          return { ...line, currentlySpeaking: true }
        }
        return { ...line }
      }
    )

    dispatch &&
      setTimeout(
        () =>
          dispatch({
            type: 'linesToSpeakSet',
            payload: {
              linesToSpeak: newLinesToSpeak,
              stringToSpeak: state.stringToSpeak
            }
          }),
        100
      )
    // speak next line
    const nextLine = newLinesToSpeak[previousLine]

    !state.sessionPaused && nextLine && uneeqContext?.speakLine(nextLine?.speak)
  }
  if (
    action.type === 'mayaMessage' &&
    action.payload.type === 'infoPage' &&
    action.payload.ssml &&
    !action.payload.mute
  ) {
    !state.sessionPaused && uneeqContext?.speakLine(action.payload.ssml)
  }
}

export default mayaEchoMiddleware
