import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import ReactTooltip from 'react-tooltip'
import { Button } from 'rebass'
// TODO: I need the next icon
import { useUneeqState } from 'uneeq-react-core'
import { ReactComponent as NextIcon } from '../../app/assets/icons/next-alt.svg'
import { useUneeqContext } from '../../app/hooks/useUneeqContext'

export const MayaNextButton = () => {
  const { t } = useTranslation()

  const { mayaQuestion, linesToSpeak } = useUneeqState()

  const currentLine = linesToSpeak?.find((line: any) => line?.currentlySpeaking)
  const eachIndexMax = Math.max(...linesToSpeak.map(line => line.eachIndex))

  const { dispatch } = useUneeqContext()

  // Disable the next button in case the item we're reading is the last one
  const disable = currentLine.eachIndex === eachIndexMax

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [])

  const nextIteration = () => {
    dispatch &&
      setTimeout(
        () =>
          dispatch({
            type: 'mayaMessage',
            payload: { ...mayaQuestion, type: 'talkNextItem' }
          }),
        100
      )
  }

  return (
    <Button
      id="next"
      variant="tertiary"
      disabled={disable}
      data-tip={t('Tooltip.nextButton')}
      onClick={() => nextIteration()}
      sx={{
        flexGrow: 0,
        flexShrink: 0,
        marginBottom: 5,
        marginLeft: 4
      }}
    >
      <NextIcon /> Next Med
    </Button>
  )
}
